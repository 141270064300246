// cf. https://tanstack.com/query/latest/docs/framework/vue/examples/nuxt3
import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueQueryPlugin, QueryClient, hydrate } from '@tanstack/vue-query'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const vueQueryState = useState<DehydratedState | null>('vue-query')

    const queryClient = new QueryClient({
      defaultOptions: { queries: { staleTime: 3600000 /* 1 hour */ } }
    })
    const options: VueQueryPluginOptions = { queryClient }

    nuxtApp.vueApp.use(VueQueryPlugin, options)

    nuxtApp.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })

    return {
      provide: {
        queryClient
      }
    }
  }
})
